import validator from '@/utils/validator'
import rules from '@/utils/validator/rules'
import {parseError} from '@/utils/api'
import AccordionItem from '@/components/Page/AccordionItem'
import ListItem from './components/ListItem'

export default {
    inject: ['toast'],
    components: {
        AccordionItem,
        ListItem,
    },
    data() {
        return {
            mountedComponent: false,
            loading: false,
            form: {
                name: null,
            },
            companyTypes: [],
            validator: {},
            validationErrors: {},
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.mountedComponent = true
        })
    },
    created() {
        this.$nextTick(() => {
            this.setRules()
            this.fetchData()
        })
    },
    watch: {
        'form.name': function (val) {
            if (val) {
                this.validationErrors = {}
            }
        },
    },
    methods: {
        handleChangeField(field, event) {
            this.validationErrors[field] = this.validator.validate(
                field,
                event.target.value,
            )
        },
        setRules() {
            this.validator = validator({
                name: [rules.required, rules.withoutSymbols, rules.strMax(255)],
            })
        },
        fetchData() {
            this.$http.setting
                .getCompanyTypes()
                .then((response) => {
                    this.companyTypes = response.data.data
                })
                .finally(() => {
                    window.scrollTo({top: 0, behavior: 'smooth'})
                })
        },
        addType() {
            const validationResult = this.validator.validateAll(this.form)
            if (!validationResult.hasErrors) {
                this.$http.setting
                    .addCompanyType(this.form)
                    .then((response) => {
                        this.toast('info', response.data.message)
                        this.fetchData()
                        this.clearForm()
                    })
                    .catch((err) => {
                        err = parseError(err)
                        if (err.status === 422) {
                            this.validationErrors = err.validationMessages
                        } else {
                            this.toast('warning', err.message)
                        }
                    })
                    .finally()
            } else {
                this.validationErrors = validationResult.validationErrors
            }
        },
        clearForm() {
            this.form.name = ''
            this.validationErrors = {}
        },
    },
}
