<template>
    <div style="max-height: 65vh; overflow-y: auto; padding: 0;">
        <DeleteItem v-model="typeToDelete" @delete="deleteType"/>
        <div v-for="(type, index) in types" :key="index"
             :class="['itemType p-3 mx-0 d-flex align-items-center justify-content-between', { bgItemType: index > 0 && index % 2 > 0 }]">
            <template v-if="form.company_type_id === type.id">
                <div class="ps-3">
                    <CFormInput v-model="form.name" maxlength="255" :invalid="!!validationErrors.name"
                                @keyup.enter="edit"/>
                    <CFormText v-if="validationErrors.name" :style="`color: ${validationErrors.name && 'red'}`">
                        {{ validationErrors.name }}
                    </CFormText>
                </div>
                <div class="text-end p-0">
                    <CButton class="p-0 m-0" variant="ghost" title="save" style="padding: 0 5px!important">
                        <CIcon name="cil-save" @click="edit"/>
                    </CButton>
                    <CButton class="p-0 m-0" variant="ghost" title="close" style="padding: 0 5px!important">
                        <CIcon name="cil-x-circle" @click="clearForm"/>
                    </CButton>
                </div>
            </template>

            <template v-else>
                <div class="ps-3 title">{{ type.name }}</div>
                <div v-if="!type.is_default" class="text-end p-0">
                    <CButton class="p-0 m-0" variant="ghost" title="edit" style="padding: 0 5px!important">
                        <CIcon name="cil-pen" @click="handleEdit(type)"/>
                    </CButton>
                    <CButton class="p-0 m-0" variant="ghost" title="delete" style="padding: 0 5px!important">
                        <CIcon name="cil-trash" @click="handleDelete(type)"/>
                    </CButton>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import validator from '@/utils/validator'
import rules from '@/utils/validator/rules'
import {parseError} from '@/utils/api'
import DeleteItem from './DeleteItem'

export default {
    name: 'ListItem',
    components: {
        DeleteItem,
    },
    inject: ['toast'],
    props: {
        types: {type: Array, default: () => []},
    },
    emits: ['change'],
    data() {
        return {
            form: {
                company_type_id: undefined,
                name: '',
            },
            typeToDelete: {},
            validator: {},
            validationErrors: {},
        }
    },
    watch: {
        'form.name': function (val) {
            if (val) {
                this.validationErrors = {}
            }
        },
    },
    mounted() {
        this.validator = validator({
            name: [rules.required, rules.withoutSymbols, rules.strMax(255)],
        })
    },
    methods: {
        handleEdit(type) {
            this.form.company_type_id = type.id
            this.form.name = type.name
        },
        handleDelete(type) {
            this.typeToDelete = type
        },
        clearForm() {
            this.form = {
                company_type_id: undefined,
                name: '',
            }
        },
        deleteType(id) {
            this.$http.setting
                .deleteCompanyType({company_type_id: id})
                .then((response) => {
                    this.toast('info', response.data.message)
                    this.$emit('change')
                })
                .catch((err) => {
                    this.toast('warning', err.message)
                })
                .finally()
        },
        edit() {
            const validationResult = this.validator.validateAll(this.form)
            if (!validationResult.hasErrors) {
                this.$http.setting
                    .editCompanyType(this.form)
                    .then((response) => {
                        this.$emit('change')
                        this.toast('info', response.data.message)
                        this.clearForm()
                    })
                    .catch((err) => {
                        err = parseError(err)
                        if (err.status === 422) {
                            this.validationErrors = err.validationMessages
                        } else {
                            this.toast('warning', err.message)
                        }
                    })
                    .finally()
            } else {
                this.validationErrors = validationResult.validationErrors
            }
        },
    },
}
</script>

<style scoped>

</style>
